import React from "react";
import styled from "styled-components";
import { Tilt } from "react-tilt";
import StyledStarsCanvas from "../Canvas/Starts";

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content-center;
position: relative;
z-index: 1;
align-items: center;
`;
const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 52px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const SkillsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 50px;
  justify-content: center;
`;
const Skill = styled.div`
  width: 100%;
  height: 200px;
  max-width: 500px;
  background-color: rgba(19, 20, 70, 255);
  border: 1px solid rgba(255, 255, 255, 0.125);
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 16px;
  padding: 18px 36px;
  background-color: ${({ theme }) => theme.card};

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }

  @media (max-width: 500px) {
    width: 100%;
    height: 200px;
  }
`;

const SkillFee = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 16px;
`;
const FeeText = styled.div`
  font-size: 35px;
  font-weight: 700;
  color: #2686b7; /* Màu xanh lá nhạt cho phần phí */
  text-align: center;
`;
const SkillTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  color: ${({ theme }) => theme.text_primary};
`;

const SkillList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 20px;
`;
const SkillItem = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 80};
  border: 1px solid ${({ theme }) => theme.text_primary + 80};
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 12px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    padding: 6px 12px;
  }
`;

const SkillImage = styled.img`
  width: 24px;
  height: 24px;
`;
const StyledSpan = styled.span`
  color: #72faa8; /* Màu xanh lá nhạt cho số tiền */
`;

const Skills = (darkMode) => {
  const skills = [
    {
      title: (
        <>
          Spend less than <StyledSpan>$20,000</StyledSpan> per month.
        </>
      ),
      fee: "• 7% fee •",
    },
    {
      title: (
        <>
          Spend from <StyledSpan>$20,000</StyledSpan> to{" "}
          <StyledSpan>$70,000</StyledSpan>.
        </>
      ),
      fee: "• 6% fee •",
    },
    {
      title: (
        <>
          Spend from <StyledSpan>$70,000</StyledSpan> to{" "}
          <StyledSpan>$150,000</StyledSpan> a month.
        </>
      ),
      fee: "• 5% fee •",
    },
    {
      title: (
        <>
          Spend over <StyledSpan>$150,000</StyledSpan> a month.
        </>
      ),
      fee: "• 4% fee •",
    },
  ];
  const StarsBackground = styled(StyledStarsCanvas)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  `;
  return (
    <Container id="Services">
      <StarsBackground />
      <Wrapper>
        <Title>Account rental service</Title>
        <SkillsContainer>
          {skills.map((skill, index) => (
            <Tilt key={`skill-${index}`}>
              <Skill>
                <SkillTitle>{skill.title}</SkillTitle>
                <SkillFee>
                  <FeeText>{skill.fee}</FeeText>
                </SkillFee>
              </Skill>
            </Tilt>
          ))}
        </SkillsContainer>
      </Wrapper>
    </Container>
  );
};

export default Skills;
