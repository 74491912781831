import React from "react";
import styled from "styled-components";
import { Bio } from "../../data/constants";
import Typewriter from "typewriter-effect";
import HeroImg from "../../images/icon.png";
import LogoImg from "../../images/logo.png";
import HeroBgAnimation from "../HeroBgAnimation";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import {
  headContainerAnimation,
  headContentAnimation,
  headTextAnimation,
} from "../../utils/motion";
import StyledStarsCanvas from "../Canvas/Starts";

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 80px 30px;
  z-index: 1;

  @media (max-width: 960px) {
    padding: 66px 16px;
  }

  @media (max-width: 640px) {
    padding: 32px 16px;
  }

  clip-path: polygon(0 0, 100% 0, 100% 100%, 70% 95%, 0 100%);
`;

const HeroInnerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1100px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const HeroLeftContainer = styled.div`
  width: 70%;
  flex-grow: 2;
  order: 1;

  @media (max-width: 960px) {
    order: 2;
    margin-bottom: 30px;
    display: flex;
    gap: 6px;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const HeroRightContainer = styled.div`
  width: 30%;
  order: 2;
  display: flex;
  justify-content: end;

  @media (max-width: 960px) {
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-contents: center;
    margin-bottom: 80px;
    width: 100%;
  }

  @media (max-width: 640px) {
    margin-bottom: 30px;
  }
`;

const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px; /* Khoảng cách giữa TitleA và Image */
  flex-direction: row; /* Default to row on larger screens */

  @media (max-width: 960px) {
    flex-direction: column; /* Stack vertically on mobile */
    align-items: center; /* Center the items */
  }
`;

const TitleA = styled.div`
  font-weight: 700;
  font-size: 35px;
  line-height: 68px;
  color: #72faa8;
  flex: 7; /* Chiếm 7 phần */

  @media (max-width: 960px) {
    text-align: center;
    order: 2; /* Ensure TitleA comes below Image */
  }

  @media (max-width: 960px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 8px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: 280px;
  flex: 3; /* Chiếm 3 phần */

  @media (max-width: 960px) {
    order: 1; /* Ensure Image comes above TitleA */
    max-width: 280px;
    max-height: 280px;
    margin-bottom: 8px; /* Add some spacing between Image and TitleA */
  }
`;

const Title = styled.div`
  font-weight: 700;
  color: #72faa8;
  font-size: 40px;
  line-height: 68px;

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 960px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 8px;
  }
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 42px;
  color: ${({ theme }) => theme.text_primary + 95};

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 960px) {
    font-size: 16px;
    line-height: 32px;
  }
`;

const ResumeButton = styled.a`
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  text-decoration: none;

  width: 95%;
  max-width: 300px;
  text-align: center;
  padding: 16px 0;

  background: hsla(271, 100%, 50%, 1);
  background: linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
  background: -moz-linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
  background: -webkit-linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
  border-radius: 50px;
  font-weight: 600;
  font-size: 20px;

  &:hover {
    transform: scale(1.05);
    transition: all 0.4s ease-in-out;
    filter: brightness(1);
  }

  @media (max-width: 640px) {
    padding: 12px 0;
    font-size: 18px;
  }
  color: white;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 450px;

  @media (max-width: 640px) {
    max-width: 280px;
    max-height: 280px;
  }
`;

const HeroBg = styled.div`
  position: absolute;
  display: flex;
  justify-content: end;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 1360px;
  overflow: hidden;
  padding: 0 30px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  @media (max-width: 960px) {
    justify-content: center;
    padding: 0 0px;
  }
`;

const Hero = () => {
  return (
    <div id="About">
      <HeroContainer>
        <HeroBg>
          <StyledStarsCanvas />
          <HeroBgAnimation />
        </HeroBg>

        <motion.div {...headContainerAnimation}>
          <HeroInnerContainer>
            <HeroLeftContainer>
              <motion.div {...headTextAnimation}>
                <TitleGroup>
                  <TitleA>
                    We provide advertising resources and social media accounts
                    with high safety and security criteria
                  </TitleA>
                  <Image src={LogoImg} alt="" />
                </TitleGroup>
              </motion.div>

              <motion.div {...headContentAnimation}>
                <SubTitle>
                  - We accept returns in case of receipt of an item or resource
                  that does not match the description. <br />- When the account
                  is checked or a problem occurs during use. We will try our
                  best to assist customers in overcoming and in some cases
                  unexpected errors occur. We can exchange it for you.
                </SubTitle>
              </motion.div>

              <ResumeButton href={Bio.resume} target="_blank">
                Contact us
              </ResumeButton>
            </HeroLeftContainer>
            <HeroRightContainer>
              <motion.div {...headContentAnimation}>
                <Tilt>
                  <Img src={HeroImg} alt="" />
                </Tilt>
              </motion.div>
            </HeroRightContainer>
          </HeroInnerContainer>
        </motion.div>
      </HeroContainer>
    </div>
  );
};

export default Hero;
