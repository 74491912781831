import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Tilt } from "react-tilt";
import StyledStarsCanvas from "../Canvas/Starts"; // Assuming this is your stars background component
import facebookImg from "../../images/facebook.png"; // Path to your facebook image
import instagramImg from "../../images/instagram.png"; // Path to your instagram image
import telegramImg from "../../images/telegram.png"; // Path to your telegram image
import discordImg from "../../images/discord.png"; // Path to your discord image

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  text-align: center;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.text_primary};
  overflow: hidden;
`;

const StarsBackground = styled(StyledStarsCanvas)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  gap: 12px;
  z-index: 1;
`;

const Title = styled.div`
  font-size: 52px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background: ${({ active }) =>
    active
      ? "linear-gradient(225deg, hsla(48, 100%, 50%, 1) 0%, hsla(33, 100%, 50%, 1) 100%)"
      : "linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%)"};
  color: ${({ theme }) => theme.text_primary};
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s, background 0.3s;
  gap: 8px;

  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.hoverTab : theme.hoverTab};
  }
`;

const AccountsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 40px 0;
  justify-items: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const Account = styled.div`
  width: 280px;
  height: 260px;
  background-color: ${({ theme }) => theme.card};
  border: 1px solid rgba(255, 255, 255, 0.125);
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 16px;
  padding: 18px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  @media (max-width: 768px) {
    width: 280px;
    height: 260px;
  }

  @media (max-width: 500px) {
    width: 280px;
    height: 260px;
  }
`;

const AccountTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
  color: ${({ theme }) => theme.text_primary};
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

const ImageTab = styled.img`
  width: 20px;
  height: 20px;
`;

const Button = styled.button`
  margin-top: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 15px;
  background-color: #1d72b8;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
  background: hsla(271, 100%, 50%, 1);
  background: linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
  &:hover {
    background-color: #155a8a;
  }
`;

const groupAccountsByType = (accounts) => {
  return accounts.reduce((groups, account) => {
    if (!groups[account.type]) groups[account.type] = [];
    groups[account.type].push(account);
    return groups;
  }, {});
};

const typeImages = {
  facebook: facebookImg,
  instagram: instagramImg,
  telegram: telegramImg,
  discord: discordImg,
};

const Accounts = () => {
  const accounts = [
    {
      title: "Business accounts have 5 accounts with a limit of $250 available",
      type: "facebook",
    },
    {
      title: "Business account has appealed the restriction",
      type: "facebook",
    },
    {
      title: "Large business accounts have unlimited spending",
      type: "facebook",
    },
    { title: "Business account limit 350 after spending", type: "facebook" },
    { title: "Business account spending limit of $50", type: "facebook" },
    {
      title: "Personal ad accounts with $250 limit can update info",
      type: "facebook",
    },
    {
      title: "Personal advertising accounts with a spending limit of $1500",
      type: "facebook",
    },
    { title: "Clone account with a USA IP", type: "facebook" },
    {
      title: "USA national personal account has many good interactive posts",
      type: "facebook",
    },
    { title: "Clone accounts with multinational IPs", type: "facebook" },
    { title: "USA user accounts have dating functions", type: "facebook" },
    {
      title: "Multinational user accounts with dating functionality",
      type: "facebook",
    },
    {
      title:
        "Multinational user accounts appealed the advertising restrictions",
      type: "facebook",
    },
    {
      title: "Multinational personal accounts can rent green check button",
      type: "facebook",
    },
    { title: "Fanpage has real followers", type: "facebook" },
    { title: "Fanpage has a green check mark", type: "facebook" },
    {
      title: "Old FanPage successfully appealed advertising restrictions",
      type: "facebook",
    },
    {
      title: "Verified high-trust Outlook accounts are not deleted",
      type: "facebook",
    },
    {
      title:
        "Instagram accounts with multinational IPs make money from threads",
      type: "instagram",
    },
    {
      title:
        "Multinational Instagram accounts created for many years can rent green check button",
      type: "instagram",
    },
    {
      title: "Instagram accounts with 2FA security with many followers",
      type: "instagram",
    },
    {
      title:
        "Telegram account has been heated - add member - spam - seeding - airdrop",
      type: "telegram",
    },
    {
      title: "Telegram account created with a super strong phone - AIRDROP",
      type: "telegram",
    },
    {
      title: "Basic telegram account specializing in airdrops - AIRDROP",
      type: "telegram",
    },
    {
      title:
        "Discord accounts created by multinational users by year 2015-2019 - AIRDROP",
      type: "discord",
    },
    {
      title: "Token and Full Ver + 2FA Discord account - AIRDROP",
      type: "discord",
    },
  ];

  const groupedAccounts = groupAccountsByType(accounts);

  // Create refs for each section
  const sectionRefs = {
    facebook: useRef(null),
    instagram: useRef(null),
    telegram: useRef(null),
    discord: useRef(null),
  };

  // Scroll to the section when tab is clicked
  const handleTabClick = (type) => {
    sectionRefs[type].current.scrollIntoView({ behavior: "smooth" });
  };

  const handleButtonClick = () => {
    window.open(
      "https://docs.google.com/spreadsheets/d/1GpbzvpmztJvbuzAALpYi9IKFaITfwbT5Bz7AF5El_KQ/edit?gid=0#gid=0",
      "_blank"
    );
  };

  return (
    <Container id="Services">
      <StarsBackground />
      <Wrapper>
        <Title>Ad-supported account types</Title>
        <TabsContainer>
          {Object.keys(groupedAccounts).map((type) => (
            <Tab key={type} active={false} onClick={() => handleTabClick(type)}>
              <ImageTab src={typeImages[type]} alt={`${type} icon`} />
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </Tab>
          ))}
        </TabsContainer>
        {Object.entries(groupedAccounts).map(([type, accounts]) => (
          <div key={type} ref={sectionRefs[type]}>
            <Title>{type.charAt(0).toUpperCase() + type.slice(1)}</Title>
            <AccountsContainer>
              {accounts.map((acc, index) => (
                <Tilt key={`account-${index}`}>
                  <Account>
                    <Image
                      src={typeImages[acc.type]}
                      alt={`${acc.type} icon`}
                    />
                    <AccountTitle>{acc.title}</AccountTitle>
                    <Button onClick={handleButtonClick}>See price</Button>
                  </Account>
                </Tilt>
              ))}
            </AccountsContainer>
          </div>
        ))}
      </Wrapper>
    </Container>
  );
};

export default Accounts;
