import styled from "styled-components";
import Icon from "../../images/logo.png"

const FooterContainer = styled.div`
  width: 100%;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  /* background: linear-gradient(100.26deg, rgba(0, 102, 255, 0.05) 42.33%, rgba(150, 0, 225, 0.05) 127.07%); */
`;

const FooterWrapper = styled.footer`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  color: ${({ theme }) => theme.text_primary};
`;

const Logo = styled.img`
  width: 180px; /* Adjust the width as needed */
  height: auto; /* Keeps the aspect ratio of the logo */
`;

const Nav = styled.nav`
  width: 100%;
  max-width: 1200px;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
`;

const NavLink = styled.a`
  color: ${({ theme }) => theme.text_primary};
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Copyright = styled.p`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.soft2};
  text-align: center;
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterWrapper>
        <Logo src={Icon} alt="Lakky Agency Logo" />{" "}
        {/* Update the src path to your logo image */}
        <Nav>
          <NavLink href="/#About">About</NavLink>
          <NavLink href="/#Services">Account rental service</NavLink>
          <NavLink href="/Accounts">Ad-supported account types</NavLink>
          <NavLink href="/#SpecialPreferential">Special Preferential</NavLink>
          <NavLink href="/#Payment">Payment</NavLink>
          {/* <NavLink href="#AdditionalServices">Additional Services</NavLink> */}
          <NavLink href="#Contact">Contact</NavLink>
        </Nav>
        {/* <Copyright>
          &copy; 2024 Ho Thanh Vinh. All rights reserved.
        </Copyright> */}
      </FooterWrapper>
    </FooterContainer>
  );
}

export default Footer;
