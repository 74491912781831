import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import ReactModal from "react-modal";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Icon1 from "../../images/icon-4-1.png";
import Icon2 from "../../images/icon-4-2.png";
import Icon3 from "../../images/icon-4-3.png";
import Icon4 from "../../images/icon-4-4.png";
import Anh1 from "../../images/anh1.png";
import Anh2 from "../../images/anh2.png";
import Anh3 from "../../images/anh3.png";
import Anh4 from "../../images/anh1.png";
import Anh5 from "../../images/anh2.png";
import Anh6 from "../../images/anh3.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 200px;
`;

const Image = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
`;

const HighlightedText = styled.span`
  font-weight: 700;
  color: #72faa8;
`;

const SliderContainer = styled.div`
  width: 80%;
  margin-top: 20px;
`;

const SlideImage = styled.img`
  width: 380px;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
`;

const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
`;

const ServiceOverview = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(3); // Set default to 3

  // Function to determine the number of slides based on window width
  const updateSlidesPerView = () => {
    if (window.innerWidth < 768) {
      setSlidesPerView(1); // 1 slide for mobile view
    } else if (window.innerWidth < 1024) {
      setSlidesPerView(2); // 2 slides for tablet view
    } else {
      setSlidesPerView(3); // 3 slides for desktop view
    }
  };

  useEffect(() => {
    // Set initial slides per view
    updateSlidesPerView();

    // Update slides per view on window resize
    window.addEventListener("resize", updateSlidesPerView);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <Container>
      <ItemsContainer>
        <Item>
          <Image src={Icon1} alt="VPS, ADSPOWER" />
          <Text>
            Customers check their accounts via{" "}
            <HighlightedText>VPS, ADSPOWER</HighlightedText>
          </Text>
        </Item>
        <Item>
          <Image src={Icon2} alt="Transfer budget and fees" />
          <Text>
            Detailed reports on spending budget{" "}
            <HighlightedText>by hour</HighlightedText>
          </Text>
        </Item>
        <Item>
          <Image src={Icon3} alt="Account in 10 minutes" />
          <Text>
            We give you an account in{" "}
            <HighlightedText>10 minutes</HighlightedText>
          </Text>
        </Item>
        <Item>
          <Image src={Icon4} alt="24/7 support" />
          <Text>
            Staff will support you <HighlightedText>24/7</HighlightedText> in a
            private closed group
          </Text>
        </Item>
      </ItemsContainer>

      <SliderContainer>
        <Swiper
          spaceBetween={20}
          slidesPerView={slidesPerView} // Use state to set slides per view
          navigation
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{ delay: 3000 }}
          modules={[Navigation, Pagination, Autoplay]}
        >
          <SwiperSlide>
            <SlideImage
              src={Anh1}
              alt="Slide 1"
              onClick={() => openModal(Anh1)}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SlideImage
              src={Anh2}
              alt="Slide 2"
              onClick={() => openModal(Anh2)}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SlideImage
              src={Anh3}
              alt="Slide 3"
              onClick={() => openModal(Anh3)}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SlideImage
              src={Anh4}
              alt="Slide 4"
              onClick={() => openModal(Anh4)}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SlideImage
              src={Anh5}
              alt="Slide 5"
              onClick={() => openModal(Anh5)}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SlideImage
              src={Anh6}
              alt="Slide 6"
              onClick={() => openModal(Anh6)}
            />
          </SwiperSlide>
        </Swiper>
      </SliderContainer>

      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.8)",
            border: "none",
            padding: 0,
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.8)",
            zIndex: 1000,
          },
        }}
        ariaHideApp={false}
      >
        <div style={{ position: "relative" }}>
          <ModalImage src={selectedImage} alt="Selected" />
          <CloseButton onClick={closeModal}>X</CloseButton>
        </div>
      </ReactModal>
    </Container>
  );
};

export default ServiceOverview;
