import { ThemeProvider } from "styled-components";
import { useState } from "react";
import { darkTheme, lightTheme } from "./utils/Themes.js";
import Navbar from "./components/Navbar";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeroSection from "./components/HeroSection";
import Hero from "./components/Hero";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Experience from "./components/Experience";
import Education from "./components/Education";
import ProjectDetails from "./components/ProjectDetails";
import styled from "styled-components";
import ServiceOverview from "./components/ServiceOverview";
import Accounts from "./components/Accounts"; // Import the Accounts component

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  padding-bottom: 100px;
  background: linear-gradient(
      38.73deg,
      rgba(204, 0, 187, 0.15) 0%,
      rgba(201, 32, 184, 0) 50%
    ),
    linear-gradient(
      141.27deg,
      rgba(0, 70, 209, 0) 50%,
      rgba(0, 70, 209, 0.15) 100%
    );
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`;
function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [openModal, setOpenModal] = useState({ state: false, project: null });

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <BrowserRouter>
        <Navbar />
        <Body>
          <Routes>
            {/* Route for the main page, displaying all components */}
            <Route
              path="/"
              element={
                <div>
                  <HeroSection />
                  <Skills />
                  <Education />
                  <Experience darkMode={darkMode} />
                  <ServiceOverview />
                  <Contact />
                  <Footer />
                </div>
              }
            />
            {/* Route for /accounts displaying only the Accounts component */}
            <Route
              path="/accounts"
              element={
                <div>
                  <Hero />
                  <Accounts />
                  <Wrapper>
                    <ServiceOverview />
                    <Contact />
                  </Wrapper>
                  <Footer />
                </div>
              }
            />
          </Routes>
        </Body>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
