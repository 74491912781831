import React from "react";
import styled from "styled-components";
import { VerticalTimelineElement } from "react-vertical-timeline-component";

const Document = styled.img`
  display: none;
  height: 70px;
  width: fit-content;
  background-color: #000;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Description = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 99};
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Span = styled.div`
  display: -webkit-box;
  max-width: 100%;
`;

// const Card = styled.div`
//   width: 650px;
//   border-radius: 10px;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
//   padding: 12px 16px;
//   justify-content: space-between;
//   position: relative;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column;
//   gap: 12px;
//   transition: all 0.3s ease-in-out;
//   &:hover {
//     box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
//     transform: translateY(-5px);
//   }
//   @media only screen and (max-width: 768px) {
//     padding: 10px;
//     gap: 8px;
//     width: 300px;
//   }

//   &:hover ${Document} {
//     display: flex;
//   }

//   &:hover ${Span} {
//     overflow: visible;
//     -webkit-line-clamp: unset;
//   }

//   border: 0.1px solid #306ee8;
//   box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
// `;
const Top = styled.div`
  width: 100%;
  display: flex;
  max-width: 100%;
  gap: 12px;
  align-items: center; /* Center items vertically */
`;
const Image = styled.img`
  height: 58px;
  width: 58px;
  margin-right: 5px;
  border-radius: 10px;
  margin-top: 4px;
  @media only screen and (max-width: 768px) {
    height: 60px;
    width: 60px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content inside Body */
`;

const Role = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const Company = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text_secondary + 99};
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Date = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 80};
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

const Skills = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  margin-top: -10px;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Skill = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 99};
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
const DateStyled = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  background-color: rgba(17, 25, 40, 0.83);
  padding: 5px 10px;
  border-radius: 4px;
  display: inline-block;
`;

const ExperienceCard = ({ experience, darkMode }) => {
  return (
    <VerticalTimelineElement
      icon={
        <img
          width="100%"
          height="100%"
          alt={experience.school}
          style={{
            borderRadius: "50%",
            objectFit: "cover",
            backgroundColor: "#fff",
          }}
          src={experience.img}
        />
      }
      contentStyle={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        background: "#1d1836",
        color: "#fff",
        boxShadow: "rgba(23, 92, 230, 0.15) 0px 4px 24px",
        backgroundColor: darkMode
          ? "rgba(17, 25, 40, 0.83)"
          : "rgb(255, 255, 255)",
        border: "1px solid rgba(255, 255, 255, 0.125)",
        borderRadius: "6px",
      }}
      contentArrowStyle={{
        borderRight: "7px solid rgba(255, 255, 255, 0.3)",
      }}
      date={<DateStyled>{experience.date}</DateStyled>}
    >
      <Top>
        <Image src={experience.img} />
        <Body>
          <Role>{experience.key}</Role>
        </Body>
      </Top>
      <Description>
        {experience?.role && <Span>{experience?.role}</Span>}
      </Description>
    </VerticalTimelineElement>
  );
};

export default ExperienceCard;
