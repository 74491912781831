export const Bio = {
  resume: "https://t.me/lakky_agency",
  whatsap: "https://wa.me/84335015932",
  facebook: "https://www.facebook.com/huy.lak.2212",
  email: "https://mail.google.com/mail/?view=cm&fs=1&to=huylak2212@gmail.com",
  skype: "https://anotepad.com/notes/i7wh3gt6",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "Redux",
        image:
          "https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
      },
      {
        name: "Next Js",
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACTklEQVR4Ab1XAaQqURB9DyohSykREpRIQSAlBCoECKUFCSRCBBEAaSEABQEoCIEASCwAUICALgCo83do0//9v819XX845O7VnDkzOzP7JWGaBd3C3IJpQVjAHeJ+Rs9a97vKLGrBsB1KgMhEP3FMUUwt4ENMfxr1yQIU4SSjRkbeOZtERmHk6pXQVDlnkHh9S+QLTm1hkiz4n/gzFQuny9FoFLquE+i34x+n02k0m00UCoV3BIzn3MMJrVYLtp1OJ0cS/X4f5/MZhmG8IyDsWtDfEaDIn2232/3zbrvdxuFwwGg04qRBt+VnETBNE0IIkE2n07/erdfrWK/X6Ha73Hb9ZXII3G43ivy3dNRqtZe7lUoFs9mM6oBDwCQCgquALT1FT3a5XF7qIZ/PYzgcolqtcggIIgBZAgRKB6lCRalp2uM8k8mAVMrlchwC+DEBipycE4n5fP44j8ViKJVKSCaTbAJCpgaez4vFIsjoWa/XA50FAgEkEgmEw2F2CkxZBZ5Br5tt1ITcbjd8Ph88Hg+7CBefECCsVitS4aVJcV9D/VMCVITk/Hq9YrPZyBBo2a1YMGvAcQYcj0cCtWMugcdYNhjDiBrP25mx3++x3W6RzWZZ8isfxzQLlsslJpMJpYY5jhkqcOH1ejEYDDAej9FoNOByuZxGsfqVzC7KTqcDSkkqleKsZOqX0mAwiHK5DGrJfr+fs5SqX8sjkQji8ThCoRC+v78Za7l6JagrUh3YkUuZpqgwDaecc9VYSDoV5Fg+at7n+eLN57kuE/EvzHr/Kvs31aYAAAAASUVORK5CYII=",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "TypeScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/4/4c/Typescript_logo_2020.svg",
      },
      {
        name: "Bootstrap",
        image:
          "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      },
    ],
  },
  {
    title: "Backend & Database",
    skills: [
      {
        name: "Node Js",
        image: "https://nodejs.org/static/images/logo.svg",
      },
      {
        name: "C#",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/C_Sharp_Logo_2023.svg/1024px-C_Sharp_Logo_2023.svg.png",
      },
      {
        name: "SQL Server",
        image:
          "https://viettelidc.com.vn//uploadimage/Root/root/06-01-toan-tap-ve-sql-server-cho-nguoi-moi-bat-dau.jpg",
      },
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
      {
        name: "Postgresql",
        image: "https://www.postgresql.org/media/img/about/press/elephant.png",
      },
      {
        name: "MongoDB",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg",
      },
    ],
  },
  {
    title: "Mobile",
    skills: [
      {
        name: "Java",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
      },
      {
        name: "Kotlin",
        image:
          "https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg",
      },
      {
        name: "React Native",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image: "https://avatars.githubusercontent.com/u/18133?s=280&v=4",
      },
      // {
      //   name: "GitHub",
      //   image:
      //     "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      // },
      {
        name: "Jira",
        image:
          "https://avatars.slack-edge.com/2018-11-30/493753869479_4b703f4119efe3d7d0ff_512.png",
      },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Visual Studio",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Visual_Studio_Icon_2022.svg/1200px-Visual_Studio_Icon_2022.svg.png",
      },
      {
        name: "Postman",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgrNRXpqTDvCfa43rwYd09pt_MpJLV41HrPw&s",
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    date: "1",
    key: "Transparent Budget Reporting",
    img: "https://firebasestorage.googleapis.com/v0/b/shop-a080e.appspot.com/o/budget%20(1).png?alt=media&token=98104820-9e15-4f0c-b7ce-0b769ee35642",
    role: "Coming to us, you will know full transparent budget reporting information updated continuously throughout the day right after there are changes in the budget on a separate and specific report.",
  },
  {
    date: "2",
    key: "Banking Account with Credit Card",
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/shop-a080e.appspot.com/o/accounting.png?alt=media&token=a5aa9ae0-a813-4b6a-a876-fbadf7abe68b",
    role: "After confirming that the customer starts working, we will provide the customer with an account that suits the customer's needs, including a credit card available.",
  },
  {
    date: "3",
    id: 2,
    key: "Multiple Payment Methods",
    img: "https://firebasestorage.googleapis.com/v0/b/shop-a080e.appspot.com/o/debit-card%20(1).png?alt=media&token=516903f8-6b06-4509-b3bf-b86084b4626a",
    role: "We accept payment methods such as USDT, ETH, Payoneer, paypal.",
  },
  {
    date: "4",
    id: 2,
    key: "Remaining Amount Refund",
    img: "https://firebasestorage.googleapis.com/v0/b/shop-a080e.appspot.com/o/refund.png?alt=media&token=4d58d166-9620-4451-9950-1fd9282da18a",
    role: "Refund the remaining amount (after fees) from the decision to stop cooperation and pay within a few hours.",
  },
  {
    date: "5",
    id: 2,
    key: "Refund for Service Interruption",
    img: "https://firebasestorage.googleapis.com/v0/b/shop-a080e.appspot.com/o/saving.png?alt=media&token=9e79ab3c-01a2-4582-a528-2a4ae8f8e382",
    role: "If the customer temporarily stops running due to unexpected reasons, we will also refund the remaining amount after calculating the fee.",
  },
];

export const education = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/shop-a080e.appspot.com/o/trial.png?alt=media&token=24b58693-44c0-4dc0-acf0-769f2f4a9af2",
    school:
      "1: For new customers who have never used the service, we will have a 10-day trial program (Applicable to customers spending over 100 USD per day). After 10 days, a 5% fee will begin for the first month applicable to all spending levels.",
  },
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/shop-a080e.appspot.com/o/dollar.png?alt=media&token=123d3ac9-916f-4355-bc9e-e9993c16ef08",
    school:
      "2: For customers who stay for more than 1 month, when the advertising account reaches the threshold of $ 900, we will cancel the account and refund the customer the amount of $ 450 directly. The customer will then receive a new ad account and continue running.",
  },
];

export const projects = [
  {
    id: 0,
    title:
      "1: Accounts that have verified their identities, have green check records for advertising, and individuals have appealed against advertising.",
    image:
      "https://www.blackhatworld.com/media/feedback-from-customers-by-lakkyagency-png.10672/full",
  },
  {
    id: 4,
    title: "2: Fanpage has appealed for advertising if customers need it.",
    image:
      "https://www.blackhatworld.com/media/feedback-from-customers-by-lakkyagency2-png.10673/full",
  },
  {
    id: 2,
    title: "3: Green fanpage for customers.",
    image:
      "https://www.blackhatworld.com/media/feedback-from-customers-by-lakkyagency3-png.10674/full",
  },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
